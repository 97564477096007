<template>
  <div>
    <Breadcrumb :model="items"/>
    <div class="page p-mt-3 p-p-3">
      <div class="p-d-flex">
        <div class="p-d-flex p-ai-center p-mr-3">
          <label class="p-mr-3">关键词</label>
          <div class="p-mr-3">
            <InputText type="text"/>
          </div>
          <div>
            <Button label="查询"/>
          </div>
        </div>
        <div class="p-d-flex p-ai-center">
          <label class="p-col-fixed">问题标题</label>
          <div class="p-mr-3">
            <Dropdown v-model="sts" :options="state" optionLabel="name" option-value="code" placeholder="选择状态"/>
          </div>
          <div>
            <Button label="重置" @click="sts=null"/>
          </div>
        </div>
      </div>
      <div class="p-mt-6 p-grid">
        <div v-for="item of data" :key="item.id" class="p-col-6 p-p-2">
          <div style="border: 1px solid #999">
            <div class="p-grid p-p-4">
              <div class="p-col-9">
                <h4>{{item.title}}</h4>
                <div class="p-mb-2">2021-03-23</div>
                <div>{{item.brief}}</div>
              </div>
              <div class="p-col-3 p-grid p-jc-end p-ai-start">
                <Button class="p-button-link" label="查看详情" @click="goto(item.id)"/>
              </div>
            </div>
            <div class="p-pl-4 p-pr-6 p-pt-3 p-pb-3">
              <Timeline :value="item.mylist" align="top" layout="horizontal">
                <template #content="slotProps">
                  {{ slotProps.item }}
                </template>
              </Timeline>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "StatusQuery",
  data() {
    return {
      data:[],
      items: [
        {label: '业务需求管理', to: '/'},
        {label: '受理状态查询'}
      ],
      sts: null,
      state: [
        {name: '已受理', code: '1'},
        {name: '研讨中', code: 'RM'},
        {name: '结果', code: 'LDN'},
        {name: '已完成', code: 'IST'},
      ],
      progress: ['已受理', '研讨中', '结果', '完',]
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData(){
      this.$http.get('/demands/getDemandsListByUserId').then((res)=>{
        console.log(res)
        let form =res.data
        for (let i in form){
          let state = form[i].state;
          let list= ['已受理', '研讨中', '结果', '完',]
          let mylist=[]
          for (let i in list){
            if(i<state){
              mylist.push(list[i])
            }
          }
          form[i].list=mylist
        }
        this.data=res.data
      })
    },
    goto(id) {
      this.$router.push('/businessRequirements/BusinessContent?id='+id)
    }
  }
}
</script>

<style scoped>

</style>